import {Footer} from './Footer'

const FooterWrapper = () => {

  return (
    <div>
        <div>
          <Footer />
        </div>
    </div>
  )
}

export {FooterWrapper}
