export enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export enum EnumUploadTipo {
  "Cargo" = 1,
  "Colaborador" = 2,
  "Descrição De Cargo" = 3,
}

export enum EnumDownloadTipo {
  "Relatório Geral" = 1,
}

export enum EnumTaskStatus {
  "Processando" = 1,
  "Incompleto" = 2,
  "Completo" = 3,
  "Erro" = 4,
}

export enum EnumAnaliseTopico
{
	//Tópicos de análise
	OportunidadesDeRespiro = 1,
	TerminologiasDeReferencia = 2,
	PluralidadeDeTermos = 3,
	RecursosDeInternalizacao = 4,
	PerformanceDeImpacto = 5,
	ValoracaoDaLinguagem = 6,
	CoerenciaDasEmocoes = 7,
	TemporalidadeNaComunicação = 8,
	TomDeVoz = 9,
	VolumeDaVoz = 10,
	Diccao = 11,
	PontosDeValidacao = 101,
	PontosDeMelhoria = 102,
	PontosDeExperimentacao = 103,
	PlanoDeAcao = 104,
	DiscursoSugerido = 105,
}

export enum EnumExtensao {
	mp4 = 1,
	mkv = 2,
	avi = 3,
	m4a = 4,
	wav = 5,
	mp3 = 6,
}