import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  Nome: Yup.string()
    .max(100, 'Máximo de caracteres ultrapassado.')
    .required('Obrigatório.'),
  Email: Yup.string()
    .nullable()
    .email('E-mail inválido.')
    .required('Obrigatório.')
    .max(100, 'Máximo de caracteres ultrapassado.'),
  Telefone: Yup.string()
    .required('Obrigatório.')
    .max(15, 'Máximo de caracteres ultrapassado.'),
  Empresa: Yup.string()
    .required('Obrigatório.')
    .nullable()
    .max(100, 'Máximo de caracteres ultrapassado.'),
  Mensagem: Yup.string()
    .required('Obrigatório.')
    .nullable()
    .max(200, 'Máximo de caracteres ultrapassado.'),

})

const initialValues = {
  Nome: '',
  Empresa: '',
  Telefone: '',
  Email: '',
  Mensagem: '',
}

export { initialValues, validationSchema }
