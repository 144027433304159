/* eslint-disable react-hooks/exhaustive-deps */
import { Link, useNavigate } from 'react-router-dom'
import './headerStyle.css'
import { toAbsoluteUrl } from '../../../helpers'

export function HeaderWrapper() {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    console.log(window.location.pathname);
    if (window.location.pathname == '/') {
      navigate('/', { state: { scrollToBottom: false, isIndex: true } });
    } else {
      navigate('/', { state: { scrollToBottom: true, isIndex: false  } });
    }
  };

  return (
    <div id='kt_app_header' className='app-header'>
      <div
        id='kt_app_header_container'
        className={'app-container flex-lg-grow-1 w-100 header-container'}
      >
        <div className="header-logo">
          <Link to={'/'}><img src={toAbsoluteUrl('/media/guizoSite/guizo-logo.png')} alt="" /></Link>
        </div>
        <div className="header-button-container">
          <button type="button" onClick={handleButtonClick}>Entre em contato </button>
        </div>
      </div>
    </div>
  )
}
