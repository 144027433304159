/* eslint-disable react/jsx-no-target-blank */
import { toAbsoluteUrl } from '../../../helpers'

const Footer = () => {

  return (
    <>
      <div className="footer">
        <div className="footer-logo">
          <img src={toAbsoluteUrl('/media/guizoSite/guizo-logo.png')} alt="" />
        </div>
        <div className="footer-infos">
          <div className="infos-card">
            <p className="footer-info-title">Contato</p>
            <hr />
            <p>11 99347 0056</p>
            <p>comercial.guizo@pro4tech.com.br</p>
          </div>
          {/* <div className="infos-card">
              <p className="footer-info-title">Segurança e privacidade</p>
              <hr />
              <p>Políticas de Privacidade Legal</p>
          </div>
          <div className="infos-card">
              <p className="footer-info-title">Nossas redes</p>
              <hr />
              <div>
              </div>

          </div> */}
        </div>
      </div>
    </>
  )
}

export { Footer }
