import {useEffect} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {HeaderWrapper} from './components/header'
import {Content} from './components/content'
import {FooterWrapper} from './components/footer'
import {
  ThemeModeProvider,
} from '../partials'
import {PageDataProvider} from './core'
import {reInitMenu, toAbsoluteUrl} from '../helpers'
import { ScrollTop } from './components/scroll-top'

const SiteLayout = () => {
  const location = useLocation()
  useEffect(() => {
    reInitMenu()
  }, [location.key])

  // const phoneNumber = '5511993470056'; 
  // const message = 'Olá, gostaria de mais informações.'; 
  // const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <div className='d-flex flex-column flex-root app-root min-vh-100' id='kt_app_root'>
          <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
            <HeaderWrapper />
            <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
              <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                <div className='d-flex flex-column flex-column-fluid'>
                  {/* <ToolbarWrapper /> */}
                  <Content>
                    <Outlet />
                  </Content>
                </div>
                <FooterWrapper/>
              </div>
            </div>
          </div>
        </div>
        {/* <ScrollTop /> */}
      </ThemeModeProvider>
      {/* <a href={whatsappLink} target="_blank" className="whatsapp-fixo"><img src={toAbsoluteUrl('/media/guizoSite/WhatsApp_icon.png')} alt="Seja Nosso Aluno" /></a> */}

 
 
    </PageDataProvider>
  )
}

export {SiteLayout}
