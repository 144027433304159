
import { toAbsoluteUrl } from "../../../_metronic/helpers"
import "./mainPageStyle.css"
import Accordion from 'react-bootstrap/Accordion';

import Card from 'react-bootstrap/Card';
import { useEffect, useRef, useState } from 'react';
import { Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useAlert, useHttpClient } from "../../modules/services/Bundle";
import { RequestMethod } from "../../modules/services/core/_enums";
import { useFormik } from "formik";
import { initialValues, validationSchema } from "./utils/helper";
import clsx from "clsx";
import ReactInputMask from "react-input-mask";

export interface LocationState {
    scrollToBottom?: boolean;
    isIndex?: boolean;
}

function MainPage() {
    const httpClient = useHttpClient();
    const alert = useAlert()
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const bottomRef = useRef<HTMLDivElement>(null);
    const location = useLocation();
    const state = location.state as LocationState;
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(true);

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            alert
                .createDialog({
                    html: 'Deseja enviar e-mail?',
                })
                .then(async (r) => {
                    await httpClient.request({
                        method: RequestMethod.POST,
                        endpoint: '/Site/Contato',
                        data: values
                    })
                    formik.resetForm()
                    setSubmitting(false)
                })
            setSubmitting(false)
        },
    })

    const cardData = [
        {
            title: 'Automotivo',
            text: 'O Guizo eleva a experiência em concessionárias permitindo controle total do atendimento. Os clientes podem monitorar em tempo real a manutenção do veículo através de câmeras e verificar o tempo estimado para a conclusão do serviço, tudo diretamente pelo app.',
            imageUrl: '/media/guizoSite/card1.png'
        },
        {
            title: 'Hotéis e Espaços de Coworking',
            text: 'Imagine chegar a um hotel ou espaço de coworking e ser direcionado para sua vaga ou estação de trabalho exclusiva, tudo através do aplicativo Guizo. Você pode agendar serviços, como café no quarto ou reservar salas de reuniões, tornando sua estadia ou trabalho mais conveniente e personalizada.',
            imageUrl: '/media/guizoSite/card2.png'
        },
        {
            title: 'Varejo e Shopping Centers',
            text: 'Ao entrar em um shopping, você pode usar o Guizo para encontrar facilmente uma vaga de estacionamento disponível, receber ofertas exclusivas das lojas próximas e até mesmo escanear códigos QR para obter informações detalhadas sobre produtos e promoções. Sua experiência de compras nunca foi tão prática.',
            imageUrl: '/media/guizoSite/card3.png'
        },
        {
            title: 'Saúde e Clínicas',
            text: 'Em um ambiente de saúde, o Guizo permite que os pacientes acompanhem o progresso de suas consultas e procedimentos médicos em tempo real. Além disso, é possível marcar consultas, acessar informações sobre serviços médicos e até mesmo fazer reuniões virtuais com médicos, tornando o processo de cuidados de saúde mais acessível e conveniente.',
            imageUrl: '/media/guizoSite/card4.png'
        },
        {
            title: 'Turismo e Atrações',
            text: 'Ao visitar atrações turísticas, museus ou parques temáticos, o Guizo oferece informações detalhadas sobre pontos de interesse, horários de funcionamento e a capacidade de comprar ingressos e reservar tours diretamente pelo aplicativo. Sua jornada de turismo se torna mais informada e personalizada.',
            imageUrl: '/media/guizoSite/card5.png'
        },
        {
            title: 'Energia e Sustentabilidade',
            text: 'No setor de energia e sustentabilidade, o Guizo pode ser usado para monitorar e controlar dispositivos IoT, como sistemas de captação de energia solar, u uso eficiente da água ou gestão de resíduos, proporcionando aos clientes uma visão em tempo real de seus esforços ambientais.',
            imageUrl: '/media/guizoSite/card6.png'
        },
        {
            title: 'Educação e Instituições Acadêmicas',
            text: 'Nas instituições de ensino, os alunos podem usar o Guizo para agendar reuniões com professores, acessar materiais didáticos e até mesmo verificar o progresso acadêmico. É uma ferramenta que facilita a comunicação e o engajamento dos estudantes.',
            imageUrl: '/media/guizoSite/card7.png'
        },
        {
            title: 'Construção e Imobiliário',
            text: 'No setor de construção e imobiliário, o Guizo permite que os clientes acompanhem o progresso de suas construções, agendem visitas às propriedades e acessem informações detalhadas sobre os projetos disponíveis. Facilitando a compra e a construção de imóveis.',
            imageUrl: '/media/guizoSite/card8.png'
        },
        {
            title: 'Pet Shops e Clínicas Veterinárias',
            text: 'No setor de Pets, o Guizo permite que os proprietários monitorem o progresso de serviços como banho e tosa, consultas veterinárias e até mesmo cirurgias em tempo real. Além disso, os clientes podem agendar serviços para seus animais de estimação, receber atualizações e até mesmo solicitar produtos e petiscos enquanto aguardam.',
            imageUrl: '/media/guizoSite/card9.png'
        }
    ];

    const checkScrollPosition = () => {
        const scrollLeft = scrollContainerRef.current?.scrollLeft || 0;
        const scrollWidth = scrollContainerRef.current?.scrollWidth || 0;
        const clientWidth = scrollContainerRef.current?.clientWidth || 0;
        setShowLeftArrow(scrollLeft > 0);
        setShowRightArrow(scrollLeft + clientWidth < scrollWidth);
    };

    const scrollLeft = () => {
        scrollContainerRef.current?.scrollBy({
            left: -420,
            behavior: 'smooth'
        });
    };

    const scrollRight = () => {
        scrollContainerRef.current?.scrollBy({
            left: 420,
            behavior: 'smooth'
        });
    };

    function scrollToBottom() {
        bottomRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'nearest',
        });
    };

    if (state?.isIndex) {
        scrollToBottom()
        state.isIndex = false
    }

    useEffect(() => {
        if (state?.scrollToBottom) {
            bottomRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
                inline: 'nearest',
            });
            scrollToBottom()
            state.scrollToBottom = false
        }
        checkScrollPosition();
        const handleScroll = () => {
            checkScrollPosition();
        };
        scrollContainerRef?.current?.addEventListener('scroll', handleScroll);

        return () => {
            scrollContainerRef?.current?.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <div className="header-white-container">
                <div className="header-white-container-text">
                    <p>Como você <b>conecta, envolve e atende</b> importa. Faça isso com o Guizo.</p>
                </div>
                <div className="header-white-image-container">
                    <img src={toAbsoluteUrl('/media/guizoSite/imagem_banner.png')} alt="" >
                    </img>
                    <div className="header-white-contato-container">
                        <div className="contato-top">
                            <p>Converse com nosso time e saiba mais.</p>
                        </div>
                        <div >
                            <form className="contato-form" action="">
                                <div className="contato-form-input">
                                    <label htmlFor="email">Digite seu e-mail</label>
                                    <input id="email" type="text" {...formik.getFieldProps('Email')} />
                                </div>
                                <div className="contato-form-button">
                                    <button type="button" onClick={scrollToBottom}>Enviar</button>
                                </div>
                            </form>
                        </div>
                        <div className="contato-bottom">
                            <p>Precisa de ajuda? Entre em contato conosco pelo <b>telefone 11 99347 0056</b></p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="escolher-container">
                <div className="escolher-title-container">
                    <h1>Porque escolher o Guizo?</h1>
                </div>
                <div className="escolher-content-container">
                    <div className="image-container">
                        <div className="escolher-image-container">
                            <img src={toAbsoluteUrl('/media/guizoSite/section2.png')} alt="" ></img>
                        </div>
                    </div>
                    <div className="escolher-text-container">
                        <p>O Guizo é uma plataforma inovadora que transforma a experiência do cliente, permitindo interações personalizadas e eficientes por meio da integração de tecnologias avançadas, como IoTs (Internet das Coisas). Com uma interface intuitiva, o Guizo proporciona um atendimento transparente e contínuo, melhorando significativamente a satisfação do cliente e promovendo a fidelização.</p>

                        <p>Além de sua eficiência, ele é extremamente versátil e pode ser adaptado a diversos setores de mercado, como <b>concessionárias, hotéis, hospitais, lojas de varejo</b> e muitos outros. Ao adotar o Guizo, as empresas conseguem oferecer uma experiência única e envolvente, fortalecendo o relacionamento com seus clientes.</p>
                    </div>
                </div>
            </div>

            <div className="explore-container">
                <div className="explore-title-container">
                    <h1>Explore o poder do Guizo</h1>
                </div>
                <div className="explore-content-container">
                    <div className="explore-text-container">
                        <p>O Guizo opera através de uma plataforma integrada que utiliza a tecnologia de <b>Internet das Coisas (IoT)</b> para oferecer uma experiência de atendimento altamente personalizada e eficiente. Ao entrar no estabelecimento, os sensores e dispositivos IoT conectados ao Guizo começam a coletar dados em tempo real. Esses dados são processados por algoritmos avançados transformando a experiência do cliente.</p>
                    </div>
                    <div className="image-container">
                        <div className="explore-image-container">
                            <img src={toAbsoluteUrl('/media/guizoSite/section3.png')} alt=""></img>
                        </div>
                    </div>
                </div>
            </div>

            <div className="beneficio-container">
                <div className="beneficio-title-container">
                    <h1>Benefícios</h1>
                </div>
                <div className="beneficio-content-container">
                    <div className="beneficio-text-container">
                        <p><b>Experiência Personalizada</b></p>
                        <p>Oferece uma experiência adaptada às necessidades e preferências dos clientes, aumentando a satisfação e o engajamento.</p>
                        <br />

                        <p><b>Controle Total</b></p>
                        <p>Permite que os clientes acompanhem e participem ativamente de cada etapa do serviço, desde o início até a conclusão.</p>
                        <br />

                        <p><b>Engajamento Aumentado</b></p>
                        <p>Cria oportunidades para maior interação e fidelização dos clientes através de funcionalidades interativas e recompensas.</p>
                        <br />

                        <p><b>Acesso a Serviços Adicionais</b></p>
                        <p>Permite aos clientes acessar e solicitar serviços adicionais facilmente, agregando valor à experiência.</p>
                        <br />

                        <p><b>Transparência</b></p>
                        <p>Proporciona visibilidade completa do status dos serviços, aumentando a confiança e a transparência com os clientes.</p>
                        <br />

                        <p><b>Feedback em Tempo Real</b></p>
                        <p>Coleta feedback dos clientes instantaneamente, permitindo ajustes rápidos e melhorias contínuas.</p>
                        <br />

                        <p className="bottom-text"><b>Toda a operação do Guizo é suportada por uma infraestrutura robusta que garante segurança de dados e alta disponibilidade, proporcionando uma experiência de usuário excepcional em qualquer segmento de mercado.</b></p>
                    </div>
                </div>
            </div>

            <div className="versatilidade-container">
                <div className="versatilidade-title-container">
                    <h1>Versatilidade em Diversos Segmentos</h1>
                </div>
                <div className="carrossel-container">
                    <div className="position-relative">
                        {showLeftArrow && (
                            <Button className="carousel-control left" onClick={scrollLeft}>&lt;</Button>
                        )}
                        <div className="scroll-container" ref={scrollContainerRef}>
                            {cardData.map((card, idx) => (
                                <Card className="mb-3 card-item" key={idx}>
                                    {card.title === 'Automotivo' && (
                                        <div className="ribbon">
                                            <Link className="link" to="/caseAutomotivo"><span>Case</span></Link>
                                        </div>
                                    )}
                                    <Card.Img variant="top" src={card.imageUrl} />
                                    <Card.Body>
                                        <Card.Title>
                                            {card.title === 'Automotivo' ? (
                                                <Link className="link" to="/caseAutomotivo">{card.title}</Link>
                                            ) : (
                                                card.title
                                            )}
                                        </Card.Title>
                                        <Card.Text>{card.text}</Card.Text>
                                    </Card.Body>
                                </Card>
                            ))}
                        </div>
                        {showRightArrow && (
                            <Button className="carousel-control right" onClick={scrollRight}>&gt;</Button>
                        )}
                    </div>
                </div>
            </div>

            <div className="personalize-container">
                <div className="personalize-text-container">
                    <p><b>Personalize seu Guizo e veja os valores.</b></p>
                </div>
                <div className="personalize-button-container">
                    <button onClick={scrollToBottom}>Entre em contato</button>
                </div>
            </div>

            <div className="conheca-container">
                <div className="conheca-text-container">
                    <p><b>Conheça nosso case do setor Automotivo</b></p>
                </div>
                <div className="conheca-button-container">
                    <button><Link to='/caseAutomotivo'>Acesse aqui</Link></button>
                </div>
            </div>

            <div className="cenarios-container">
                <div className="cenarios-title-container">
                    <h1>Guizo em Diferentes Cenários:Exemplos Práticos.</h1>
                </div>
                <div className="cenarios-content-container">
                    <div className="cenarios-image-container">
                        <img src={toAbsoluteUrl('/media/guizoSite/section8.png')} alt=""></img>
                    </div>
                    <div className="cenarios-accordion-container">
                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Restaurante - Espaço Kids Inteligente</Accordion.Header>
                                <Accordion.Body>
                                    Imagine um restaurante que utiliza o Guizo para transformar a experiência das famílias que o visitam. O Guizo oferece um "Espaço Kids Inteligente", onde as crianças são equipadas com pulseiras RFID que permitem aos pais monitorar sua localização e atividades enquanto eles desfrutam de suas refeições. Além disso, o ambiente é equipado com câmeras que capturam as atividades das crianças e transmitem as imagens em tempo real para os pais em seus dispositivos móveis, garantindo que seus filhos estejam seguros e se divertindo. Os pais podem até interagir remotamente com as crianças por meio de mensagens de texto ou chamadas de voz.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Restaurante - Preparação de Alimentos ao Vivo</Accordion.Header>
                                <Accordion.Body>
                                    Neste restaurante, o Guizo revoluciona a maneira como os clientes se envolvem com a preparação de alimentos. Os clientes podem acessar um aplicativo que lhes permite acompanhar a preparação de seus pratos em tempo real, graças a câmeras colocadas nas cozinhas e na cabeça dos chefs. Eles podem ver cada etapa do processo, desde o corte de ingredientes até a finalização dos pratos. Isso não apenas cria uma experiência interativa e educacional para os clientes, mas também constrói confiança e transparência na qualidade dos alimentos servidos.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Shows e Eventos - Acesso aos Bastidores</Accordion.Header>
                                <Accordion.Body>
                                    Para eventos e shows, o Guizo oferece aos participantes uma experiência exclusiva de acesso aos bastidores. Os clientes podem utilizar o aplicativo Guizo para obter acesso virtual aos bastidores do evento, permitindo que eles vejam artistas se preparando, acompanhar os artistas durante os ensaios, técnicos configurando equipamentos e até mesmo interagir com membros da equipe. Isso cria uma conexão mais profunda entre os fãs e os artistas, proporcionando uma experiência memorável e única.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Hotéis - Personalização da Estadia Aprimorada</Accordion.Header>
                                <Accordion.Body >
                                    Em nossos hotéis, levamos a personalização da estadia a um novo patamar com o Guizo. Ao fazer o check-in, os hóspedes recebem um dispositivo conectado que não apenas abre as portas dos quartos, mas também oferece informações sobre comodidades e serviços do hotel. Além disso, os hóspedes podem agendar serviços personalizados, como solicitar seu café da manhã favorito ou reservar tratamentos de spa, tudo com um simples toque no aplicativo Guizo. Explorar a cidade também é mais fácil, pois o aplicativo fornece recomendações de restaurantes locais e atrações turísticas com base nas preferências do hóspede. Oferecemos uma estadia verdadeiramente personalizada e memorável.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>Pet Shops - Experiência de Cuidado Avançada para Animais de Estimação</Accordion.Header>
                                <Accordion.Body>
                                    Em um pet shop que utiliza o Guizo, os clientes que deixam seus animais de estimação para cuidados podem desfrutar de uma experiência aprimorada de acompanhamento e interação com seus bichinhos. Cada animal recebe uma coleira inteligente equipada com um dispositivo de rastreamento GPS e monitoramento de saúde. Os proprietários podem acompanhar a localização de seus animais de estimação em tempo real e receber atualizações sobre a saúde e o bem-estar deles por meio do aplicativo Guizo.
                                    Além disso, as áreas de cuidados dos animais de estimação são equipadas com câmeras que transmitem vídeo ao vivo para os donos, permitindo que eles vejam seus animais de estimação enquanto são banhados, tosados ou recebem tratamentos especiais. Os clientes também podem interagir com seus animais de estimação remotamente, através de um sistema de voz e câmera bidirecional, acalmando e tranquilizando seus pets durante a estadia.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>
            </div>

            {/* <div className="informe-container">
                <div className="informe-text-container">
                    <p><b>Informe seu ramo de atuação e nossa IA fornecerá ideias de como aplicar o Guizo no seu negócio.</b></p>
                </div>
                <div className="informe-button-container">
                    <button>COMECE AGORA</button>
                </div>
            </div> */}

            <div className="ajuda-container">
                <div ref={bottomRef} id="contato" className="ajuda-form-container">
                    <div className="ajuda-form">
                        <h1>Como podemos ajudar?</h1>
                        <form onSubmit={formik.handleSubmit}>
                            <input
                                maxLength={100}
                                placeholder="*Seu Nome"
                                type="text"
                                {...formik.getFieldProps('Nome')}
                                className={clsx(
                                    'mt-3 form-control',
                                    {
                                        'is-invalid': formik.touched.Nome && formik.errors.Nome,
                                    },
                                    {
                                        'is-valid': formik.touched.Nome && !formik.errors.Nome,
                                    }
                                )}
                            />
                            <input
                                maxLength={100}
                                placeholder="*Seu Email"
                                type="text"
                                {...formik.getFieldProps('Email')}
                                className={clsx(
                                    'mt-3 form-control',
                                    {
                                        'is-invalid': formik.touched.Email && formik.errors.Email,
                                    },
                                    {
                                        'is-valid': formik.touched.Email && !formik.errors.Email,
                                    }
                                )}
                            />
                            <ReactInputMask
                                mask='(99) 99999-9999'
                                placeholder="*Seu Celular"
                                {...formik.getFieldProps('Telefone')}
                                className={clsx(
                                    'mt-3 form-control',
                                    {
                                        'is-invalid': formik.touched.Telefone && formik.errors.Telefone,
                                    },
                                    {
                                        'is-valid': formik.touched.Telefone && !formik.errors.Telefone,
                                    }
                                )}
                            />
                            <input
                                maxLength={100}
                                placeholder="*Sua Empresa"
                                type="text"
                                {...formik.getFieldProps('Empresa')}
                                className={clsx(
                                    'mt-3 form-control',
                                    {
                                        'is-invalid': formik.touched.Empresa && formik.errors.Empresa,
                                    },
                                    {
                                        'is-valid': formik.touched.Empresa && !formik.errors.Empresa,
                                    }
                                )}
                            />
                            <textarea
                                maxLength={200}
                                placeholder="*Sua Mensagem"
                                rows={3}
                                {...formik.getFieldProps('Mensagem')}
                                className={clsx(
                                    'mt-3 form-control',
                                    {
                                        'is-invalid': formik.touched.Mensagem && formik.errors.Mensagem,
                                    },
                                    {
                                        'is-valid': formik.touched.Mensagem && !formik.errors.Mensagem,
                                    }
                                )}
                            />

                            <button type='submit'>Enviar</button>
                        </form>
                    </div>
                    <div className="ajuda-contato">
                        <p><b>E-mail:</b> guizo@pro4tech.com.br</p>
                        <p><b>Telefone:</b> 11 99347 0056</p>
                        <hr />
                    </div>
                </div>
                <div className="ajuda-decoration">

                </div>
            </div>
        </>
    )
}

export default MainPage