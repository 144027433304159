import { FC } from 'react'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { App } from '../App'
import { SiteLayout } from '../../_metronic/layout/SiteLayout'
import MainPage from '../pages/main/mainPage'
import CaseAutomotivoPage from '../pages/caseAutomotivo/caseAutomotivoPage'

const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route element={<SiteLayout />}>
            <Route index element={<MainPage />} />
            <Route path='/caseAutomotivo' element={<CaseAutomotivoPage />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }
