
import { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../_metronic/helpers"
import "./casePageStyle.css"
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";

function CaseAutomotivoPage() {
    const { pathname } = useLocation();
    const [showModal, setShowModal] = useState(false);
    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/', { state: { isIndex: false, scrollToBottom: true } });
    };

    return (
        <>
            {/* header-red */}
            <div className="header-red-container">
                <div className="header-case-content-container">
                    <div className="header-case-text-container">
                        <p><b>CASE AUTOMOTIVO</b></p>
                    </div>
                    <div className="header-case-image-container">
                        <img src={toAbsoluteUrl('/media/guizoSite/logo_branco.png')} alt="" ></img>
                    </div>
                </div>
            </div>

            {/* revolucionando-section  */}
            <div className="revolucionando-container">
                <div className="revolucionando-content-container">
                    <div className="revolucionando-text-container">
                        <h1>Revolucionando o Atendimento Automotivo</h1>

                        <p>A Toyota Ramires implementou o Guizo, colocando o cliente no controle total de sua experiência. Desta forma, os clientes participam ativamente de cada etapa do atendimento, desde a chegada até a conclusão do serviço, proporcionando uma experiência personalizada e eficiente. Além disso, o Guizo foi customizado com a identidade visual da Toyota Ramires, garantindo uma experiência coesa e alinhada com a imagem da marca.</p>
                        <button onClick={handleShow}>▶ Assista o vídeo</button>
                    </div>
                    <div className="revolucionando-image-container">
                        <img src={toAbsoluteUrl('/media/guizoSite/caseRevolucionando.png')} alt=""></img>
                    </div>
                </div>
            </div>

            <Modal show={showModal} onHide={handleClose} dialogClassName="custom-modal">
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <iframe
                        width="100%"
                        height="500"
                        src="https://guizo.blob.core.windows.net/assets/video_Toyota%20-%20Case.mp4?sp=r&st=2024-09-20T13:36:54Z&se=2099-09-20T21:36:54Z&spr=https&sv=2022-11-02&sr=b&sig=NvnmcRa2AVQtLChuF61VZ4JmO4D%2FtgHQ8KYRMBrkxMg%3D"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </Modal.Body>
            </Modal>


            {/* experiencia-section */}
            <div className="experiencia-container">
                <div className="experiencia-title-container">
                    <h1>Experiência do Cliente</h1>
                </div>
                <div className="experiencia-content-container">
                    <div className="image-container">
                        <div className="experiencia-image-container">
                            <img src={toAbsoluteUrl('/media/guizoSite/caseExperiencia.png')} alt="" ></img>
                        </div>
                    </div>
                    <div className="experiencia-text-container">
                        <p>Com o Guizo, os clientes podem monitorar todo o processo de revisão do veículo do início ao fim, diretamente na palma da mão. Aqui estão algumas das muitas facilidades que o Guizo oferece:</p>
                        <br />

                        <p><b>Acompanhamento da Revisão</b></p>
                        <p>Os clientes acompanham a revisão do veículo em tempo real, com acesso às câmeras do box de reparo.</p>
                        <br />

                        <p><b>Agendamento de Espaços Exclusivos</b></p>
                        <p>Os clientes podem reservar espaços como sala de reuniões, auditório tecnológico ou espaço fitness para aproveitar o tempo enquanto aguardam.</p>
                    </div>
                </div>
            </div>

            {/* list-section */}
            <div className="list-container">
                <div className="list-content-container">
                    <div className="list-text-container">
                        <p><b>Serviços Personalizados</b></p>
                        <p>Solicitação de café, acesso a serviços de lavagem de veículos e informações sobre o processo ESG, tudo pelo app.</p>
                        <br />

                        <p><b>Interatividade com Showroom</b></p>
                        <p>Utilização da câmera do celular para acessar informações detalhadas dos veículos no showroom e solicitação de atendimento de um consultor de vendas.</p>
                        <br />

                        <p><b>Test-Drive Facilitado</b></p>
                        <p>Agendamento e realização de test-drives, com acompanhamento do tempo de serviço pelo app.</p>
                        <br />

                        <p><b>Rewards e Comodidades</b></p>
                        <p>Acesso à página de membros para serviços e comodidades exclusivas.</p>
                        <br />

                        <p><b>Agendamento de Reuniões</b></p>
                        <p>Marcação de reuniões na loja diretamente pelo app.</p>
                    </div>
                </div>
            </div>

            {/* funcionalidade-section */}
            <div className="funcionalidade-container">
                <div className="funcionalidade-title-container">
                    <h1>Funcionalidades em Detalhe</h1>
                </div>
                <div className="funcionalidade-content-container">
                    <div className="funcionalidade-text-container">
                        <p>Ao chegar na portaria da Toyota Ramires, os clientes são direcionados para estacionar seus veículos em uma vaga destacada e iluminada, visível no app. Na recepção, a recepcionista estará pronta para direcioná-los rapidamente ao atendimento, que ocorre em uma sala exclusiva, acompanhada em tempo real pelo app.</p>

                        <p> Enquanto os veículos estão na oficina, os clientes podem observar o reparo em tempo real através das câmeras do box e solicitar serviços adicionais, como café, na aba de serviços do aplicativo. Além disso, enquanto passeiam pelo showroom, podem acessar informações dos veículos através de QR codes e até mesmo agendar um test-drive.</p>

                        <p>Se precisarem de uma reunião enquanto esperam, é possível reservar salas diretamente pelo app. Ao final do processo, os clientes recebem uma notificação informando o ponto de encontro com o consultor para finalizar o atendimento.</p>
                    </div>
                    <div className="image-container">
                        <div className="funcionalidade-image-container">
                            <img src={toAbsoluteUrl('/media/guizoSite/caseFuncionalidades.png')} alt=""></img>
                        </div>
                    </div>
                </div>
            </div>

            {/* beneficios-section */}
            <div className="beneficios-container">
                <div className="beneficios-title-container">
                    <h1>Benefícios para o Cliente</h1>
                </div>
                <div className="beneficios-content-container">
                    <div className="image-container">
                        <div className="beneficios-image-container">
                            <img src={toAbsoluteUrl('/media/guizoSite/caseBeneficios.png')} alt="" ></img>
                        </div>
                    </div>
                    <div className="beneficios-text-container">
                        <p><b>Transparência Total</b></p>
                        <p>Acesso em tempo real ao status do serviço do veículo.</p>
                        <br />

                        <p><b>Conveniência</b></p>
                        <p>Agendamento e uso de espaços e serviços adicionais com facilidade.</p>
                        <br />

                        <p><b>Personalização</b></p>
                        <p>Atendimento totalmente personalizado, ajustado às necessidades e preferências dos clientes.</p>
                        <br />

                        <p><b>Interatividade</b></p>
                        <p>Maior interação e engajamento com os serviços e produtos oferecidos na loja.</p>
                    </div>
                </div>
            </div>

            {/* depoimento-section */}
            <div className="depoimento-container">
                <div className="depoimento-content-container">
                    <div className="depoimento-title-container">
                        <p>Depoimentos</p>
                    </div>
                    <div className="depoimento-text-container">
                        <p>"Com o Guizo, sinto que estou sempre no controle. É muito mais fácil acompanhar o serviço do meu carro e aproveitar meu tempo na loja." - Cliente da Toyota Ramires</p>
                        <p>"A possibilidade de agendar reuniões e utilizar o espaço fitness enquanto espero meu carro foi um grande diferencial para mim." - Cliente da Toyota Ramires</p>
                    </div>
                </div>
            </div>

            {/* personalize-section */}
            <div className="personalize-container">
                <div className="personalize-text-container">
                    <p><b>Personalize seu Guizo e veja os valores.</b></p>
                </div>
                <div className="personalize-button-container">
                    <button type="button" onClick={handleButtonClick}>Entre em contato</button>
                </div>
            </div>

        </>
    )
}

export default CaseAutomotivoPage